import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'enrolment',
    loadChildren: () => import('./enrolment/enrolment.module').then(m => m.EnrolmentPageModule)
  },
  {
    path: 'employee-clocking-list',
    loadChildren: () => import('./employee-clocking-list/employee-clocking-list.module').then(m => m.EmployeeClockingListPageModule)
  },
  {
    path: 'employee-clocking',
    loadChildren: () => import('./employee-clocking/employee-clocking.module').then(m => m.EmployeeClockingPageModule)
  },
  {
    path: 'employee-attendance',
    loadChildren: () => import('./employee-attendance/employee-attendance.module').then(m => m.EmployeeAttendancePageModule)
  },
  {
    path: 'labour-management',
    loadChildren: () => import('./labour-management/labour-management.module').then(m => m.LabourManagementPageModule)
  },
  {
    path: 'leave-management',
    loadChildren: () => import('./leave-management/leave-management.module').then(m => m.LeaveManagementPageModule)
  },
  {
    path: 'labour-unavailables',
    loadChildren: () => import('./labour-unavailables/labour-unavailables-routing.module').then(m => m.LabourUnavailablesPageRoutingModule)
  },
  {
    path: 'header',
    loadChildren: () => import('./header/header.module').then(m => m.HeaderPageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule)
  },
  {
    path: 'gang-units-selector',
    loadChildren: () => import('./global/gang-units-selector/gang-units-selector.module').then(m => m.GangUnitsSelectorPageModule)
  },
  {
    path: 'gang-units-filter',
    loadChildren: () => import('./global/gang-units-filter/gang-units-filter.module').then(m => m.GangUnitsFilterPageModule)
  },
  {
    path: 'exceptions',
    loadChildren: () => import('./exceptions/exceptions.module').then(m => m.ExceptionsPageModule)
  },
  {
    path: 'labour-availability',
    loadChildren: () => import('./labour-availability/labour-availability.module').then(m => m.LabourAvailabilityPageModule)
  },
  {
    path: 'employee-list',
    loadChildren: () => import('./global/employee-list/employee-list.module').then(m => m.EmployeeListPageModule)
  },
  {
    path: 'new-employee-list',
    loadChildren: () => import('./global/new-employee-list/new-employee-list.module').then(m => m.NewEmployeeListPageModule)
  },
  {
    path: 'exceptions',
    loadChildren: () => import('./exceptions/exceptions.module').then(m => m.ExceptionsPageModule)
  },
  {
    path: 'exception-report',
    loadChildren: () => import('./exception-report-page/exception-report-page.module').then(m => m.ExceptionReportPageModule)
  },
  {
    path: 'labour-availability',
    loadChildren: () => import('./labour-availability/labour-availability.module').then(m => m.LabourAvailabilityPageModule)
  },
  {
    path: 'not-on-gang',
    loadChildren: () => import('./not-in-gang/not-in-gang.module').then(m => m.NotInGangModulePage)
  },
  {
    path: 'leave-management',
    loadChildren: () => import('./leave-management/leave-management.module').then(m => m.LeaveManagementPageModule)
  },
  {
    path: 'labour-unavailables',
    loadChildren: () => import('./labour-unavailables/labour-unavailables.module').then(m => m.LabourUnavailablesPageModule)
  },
  {
    path: 'transfers',
    loadChildren: () => import('./transfers/transfers.module').then(m => m.TransfersModule)
  },
  {
    path: 'leave-roster',
    loadChildren: () => import('./leave-roster/leave-roster.module').then(m => m.LeaveRosterModule)
  },
  {
    path: 'dashboard-items',
    loadChildren: () => import('./global/dashboard-items/dashboard-items.module').then(m => m.DashboardItemsPageModule)
  },
  {
    path: 'reports-dashboard',
    loadChildren: () => import('./reports-dashboard/reports-dashboard.module').then(m => m.ReportsDashboardPageModule)
  },
  {
    path: 'footer',
    loadChildren: () => import('./global/footer/footer.module').then(m => m.FooterPageModule)
  },
  {
    path: 'health-and-safety-dashboard',
    loadChildren: () => import('./health-and-safety-dashboard/health-and-safety-dashboard.module').then(m => m.HealthAndSafetyDashboardPageModule)
  },
  {
    path: 'safe-declaration-start',
    loadChildren: () => import('./safe-declaration-start/safe-declaration-start.module').then(m => m.SafeDeclarationStartPageModule)
  },
  {
    path: 'safe-declaration-declare',
    loadChildren: () => import('./safe-declaration-declare/safe-declaration-declare.module').then(m => m.SafeDeclarationDeclarePageModule)
  },
  {
    path: 'activity-filter',
    loadChildren: () => import('./global/activity-filter/activity-filter.module').then(m => m.ActivityFilterPageModule)
  },
  {
    path: 'activity-selector',
    loadChildren: () => import('./global/activity-selector/activity-selector.module').then(m => m.ActivitySelectorPageModule)
  },
  {
    path: 'employee-selector',
    loadChildren: () => import('./global/employee-selector/employee-selector.module').then(m => m.EmployeeSelectorPageModule)
  },
  {
    path: 'employee-filter',
    loadChildren: () => import('./global/employee-filter/employee-filter.module').then(m => m.EmployeeFilterPageModule)
  },
  {
    path: 'work-place-selector',
    loadChildren: () => import('./global/work-place-selector/work-place-selector.module').then(m => m.WorkPlaceSelectorPageModule)
  },
  {
    path: 'work-place-filter',
    loadChildren: () => import('./global/work-place-filter/work-place-filter.module').then(m => m.WorkPlaceFilterPageModule)
  },
  {
    path: 'examination-check-list',
    loadChildren: () => import('./examination-check-list/examination-check-list.module').then(m => m.ExaminationCheckListPageModule)
  },
  {
    path: 'block-screen',
    loadChildren: () => import('./global/block-screen/block-screen.module').then(m => m.BlockScreenPageModule)
  },
  {
    path: 'half-level-selector',
    loadChildren: () => import('./global/half-level-selector/half-level-selector.module').then(m => m.HalfLevelSelectorPageModule)
  },
  {
    path: 'half-level-filter',
    loadChildren: () => import('./global/half-level-filter/half-level-filter.module').then(m => m.HalfLevelFilterPageModule)
  },
  {
    path: 'examination-check-list-approval',
    loadChildren: () => import('./examination-check-list-approval/examination-check-list-approval.module').then(m => m.ExaminationCheckListApprovalPageModule)
  },
  {
    path: 'manual-bio',
    loadChildren: () => import('./manual-bio/manual-bio.module').then(m => m.ManualBioPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
