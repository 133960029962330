import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {NotificationService} from './notification.service';
import {HttpClient} from '@angular/common/http';
import {ConnectionStatus, NetworkStatusService} from './network-status.service';
import {cachableEndPoint, endPointsWithDates} from '../../environments/cachable-end-point';
import {AuthenticationService} from './authentication.service';
import {LoaderService} from './loader.service';
import {ClockService} from './clock.service';
import {OfflineSyncService} from './offline/offline-sync.service';
import {Device} from '@ionic-native/device/ngx';
import {DateTimeService} from '../global/date-time.service';
import {LandscapeService} from './landscape.service';
import {LocalStorageService} from './local-storage-service';

const API_URL: string = environment.apiUrl;
const TOKEN_KEY = environment.tokenKey;

@Injectable({
  providedIn: 'root'
})
export class InitializeStorageService {
  private currentService = '';
  private currentTableIndex = 0;
  private apiSyncBusy = false;
  private tracker;
  private currentBackSyncIndex: number = 0;
  private backToServerSyncItems: any;
  private backToServerSyncTracker: number;

  constructor(
    private platform: Platform,
    private offlineService: OfflineSyncService,
    private notification: NotificationService,
    private network: NetworkStatusService,
    private clockService: ClockService,
    private loader: LoaderService,
    protected http: HttpClient,
    private auth: AuthenticationService,
    private dateUtil: DateTimeService,
    private screen: LandscapeService,
    private storage: LocalStorageService,
    private device?: Device
  ) {
  }

  public initializeApiData() {
    if (!environment.offlineEnabled) {
      return this.notification.warning('Offline mode disabled. Please ensure your internet is always on.');
    }
    if (this.network.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      return;
    }

    setTimeout(() => {
      this.screen.blockScreen('Fetching data from server. Please wait...');
      this.tracker = setInterval(() => {
        if (this.apiSyncBusy === false && this.tracker && !this.backToServerSyncTracker) {
          this.fetchEndPoint();
        }
      }, 1000);
    }, 2500);
  }

  public sendDataToServer() {
    if (!environment.offlineEnabled) {
      return this.notification.warning('Offline mode disabled. Please ensure your internet is always on.');
    }
    if (this.network.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      return;
    }
    this.screen.blockScreen('Sending data to server. Please wait...', 'success');
    this.backToServerSyncTracker = setInterval(() => {
      if (this.apiSyncBusy === false && this.backToServerSyncTracker) {
        this.processBackToServerSync();
      }
    }, 1000);
  }

  private processBackToServerSync() {
    this.apiSyncBusy = true;
    this.backToServerSyncItems = this.storage.getPendingSync();
    if (this.backToServerSyncItems.length === 0) {
      return this.resetBackToServerSync();
    }
    this.backToServerSyncItems.forEach((item, index) => {
      this.currentBackSyncIndex = index + 1;
      if (item.method_type === 'post') {
        this.auth.setServiceName(item.service_name).create(JSON.parse(item.payload), (res) => {
          this.auth.showSuccess('Sync back to server completed for ' + item.service_name + ' (' + item.uuid + ')');
          this.backToServerSyncItems.splice(index, 1);
          this.storage.updateAllPendingSync(this.backToServerSyncItems);
        });
      }
      this.resetBackToServerSync();
    });
  }

  private resetBackToServerSync() {
    if (this.currentBackSyncIndex === this.backToServerSyncItems.length) {
      clearInterval(this.backToServerSyncTracker);
      this.backToServerSyncTracker = null;
      this.currentBackSyncIndex = 0;
      this.screen.unblockScreen();
      this.unBlockScreen();
      this.apiSyncBusy = false;
    }
  }

  private fetchEndPoint() {
    this.apiSyncBusy = true;
    this.currentService = cachableEndPoint[this.currentTableIndex];
    if (!this.currentService) {
      return;
    }
    const urlParams: any = {};
    urlParams.page = 1;
    urlParams.page_size = 100;
    if (this.currentService === 'gang-unit') {
      urlParams.employee_id = this.auth.getEmployee().uuid;
      urlParams.gang_unit_id = this.auth.getEmployee().gang_unit.uuid;
    }

    if (this.currentService === 'safe-declaration-gangs') {
      urlParams.employee_id = this.auth.getEmployee().uuid;
    }

    if (endPointsWithDates.includes(this.currentService)) {
      urlParams.start_date = this.dateUtil.dateXDaysAgo();
      urlParams.end_date = this.dateUtil.dateString();
      urlParams.page_size = 200;
    }

    urlParams.dboff = 1;

    this.getData(urlParams).then((result: any) => {
      this.storage.removeItem(this.currentService);
      if (this.currentService === 'employee-enrollment-validate') {
        this.storage.removeItem('bio_people');
      }
      let totalPages = result.total / result.per_page;
      totalPages = Math.ceil(totalPages);
      this.notification.warning(totalPages + ' total pages to sync for ' + this.currentService);

      if (totalPages === 0) {
        this.currentTableIndex++;
        this.apiSyncBusy = false;
        this.resetOptions();
        return this.notification.success('Sync completed for ' + this.currentService);
      }
      for (let i = 1; i <= totalPages; i++) {
        urlParams.page = i;
        this.getData(urlParams).then((response: any) => {
          this.notification.warning('Fetching page ' + urlParams.page + ' of ' + totalPages + ' for ' + this.currentService);
          this.storage.saveCache(this.currentService, response.items);
          if (this.currentService === 'employee-enrollment-validate') {
            this.storage.saveCache('bio_people', response.employees);
          }
          if (totalPages === response.current_page) {
            this.currentTableIndex++;
            this.apiSyncBusy = false;
            this.resetOptions();
            this.notification.success('Sync completed for ' + this.currentService);
          }
        }).catch((error) => {
          this.auth.showError('Error fetching  ' + this.currentService + ': ' + error.message)
        });
      }
    }).catch((error) => {
      // this.auth.showError('Error initial fetching  ' + this.currentService + ': ' + error.message);
    });
  }

  private resetOptions() {
    if (this.currentTableIndex === cachableEndPoint.length) {
      clearInterval(this.tracker);
      this.tracker = null;
      this.currentTableIndex = 0;
      this.screen.unblockScreen();
      this.unBlockScreen();
    }
  }

  private unBlockScreen() {
    setTimeout(() => {
      this.loader.dismissLoader();
    }, 2500);
  }

  private async getData(urlParams) {
    return this.http.get(API_URL + '/' + this.currentService, {params: this.getParams(urlParams)}).toPromise();
  }

  private getParams(urlParams) {
    const savedUser: any = localStorage.getItem('user-info');
    const savedEmployee: any = localStorage.getItem('employee-info');

    if (savedUser) {
      const user = JSON.parse(savedUser);
      if (user.hasOwnProperty('user_type') && user.user_type) {
        urlParams.user_type = user.type;
      }
    }
    if (savedEmployee) {
      const employee = JSON.parse(savedEmployee);
      urlParams.miner = employee.industry_number;
    }
    urlParams.device_id = this.getDeviceUuid();
    urlParams.access_token = localStorage.getItem(TOKEN_KEY);
    urlParams.time = new Date().getTime();
    return urlParams;
  }

  private getDeviceUuid(): any {
    return this.device.uuid;
  }
}

