import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from './services/authentication.service';
import {Platform, MenuController} from '@ionic/angular';
import {Router} from '@angular/router';
import {UserService} from './services/user.service';
import {NetworkStatusService} from './services/network-status.service';
import {InitializeStorageService} from './services/initialize-storage.service';
import {environment} from '../environments/environment';
import {SyncToServerService} from './services/sync-to-server.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public sync;
  params: any = {};
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      callback: () => this.authenticationService.isAuthenticated(),
    },
    {
      title: 'Exception Report',
      url: '/welcome',
      callback: () => this.authenticationService.isAuthenticated(),
    },
    {
      title: 'Leave Roaster',
      url: '/industries',
      callback: () => this.authenticationService.isAuthenticated(),
    },
    {
      title: 'Enrolment',
      url: '/enrolment',
      callback: () => this.authenticationService.isAuthenticated(),
    },
    {
      title: 'Employee Clocking',
      url: '/employee-clocking',
      callback: () => this.authenticationService.isAuthenticated(),
    },
    {
      title: 'Employee Clocking List',
      url: '/employee-clocking-list',
      callback: () => this.authenticationService.isAuthenticated(),
    },
    {
      title: 'Logout',
      url: '/logout',
      callback: () => this.authenticationService.isAuthenticated(),
    }
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private platform: Platform,
    private router: Router,
    private networkStatus: NetworkStatusService,
    private menuCtrl: MenuController,
    private userService: UserService,
    private initializeStorageService: InitializeStorageService,
    private onNetworkUpSynchronizeService: SyncToServerService,
    private networkService: NetworkStatusService,
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova') || this.platform.is('android')) {
        this.networkService.initializeNetworkEvents();
      }
    }).catch(error => {
      this.authenticationService.showError('Failed to initialize network.');
    });
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      localStorage.setItem(environment.syncStateName, '0');
      this.authenticationService.authenticationState.subscribe((state: boolean) => {
        if (state) {
          this.initializeStorageService.sendDataToServer();
          this.initializeStorageService.initializeApiData();
          if (this.userService.canEnrolUsers()) {
            this.router.navigate(['enrolment']);
          } else {
            this.router.navigate(['labour-management']);
          }
        } else {
          this.router.navigate(['login']);
        }
      });
    });
  }

  toggleMenu() {
    this.menuCtrl.close();
  }
}
