import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ConnectionStatus, NetworkStatusService} from '../services/network-status.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfflineResponseService {

  constructor(
    private network: NetworkStatusService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/login')) {
      return next.handle(req);
    }

    if (req.method.toLocaleLowerCase() !== 'get' && (environment.forceOffline || this.network.getCurrentNetworkStatus() === ConnectionStatus.Offline)) {
      return of(new HttpResponse({
        status: 200,
        body: 'Operation completed.'
      }));
    } else {
      return next.handle(req);
    }
  }
}
