import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Network} from '@ionic-native/network/ngx';
import {Platform} from '@ionic/angular';
import {NotificationService} from './notification.service';
import {environment} from '../../environments/environment';

export enum ConnectionStatus {
  Online = 1,
  Offline = 0
}

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {
  public status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Offline);

  constructor(private network: Network,
              private toastService: NotificationService,
              private plt: Platform) {
    this.plt.ready().then(() => {
      this.initializeNetworkEvents();
      const status = this.network.type !== 'none' ? ConnectionStatus.Online : ConnectionStatus.Offline;
      this.status.next(status);
    });
  }

  public initializeNetworkEvents() {
    this.network.onDisconnect().subscribe((state: any) => {
      if (this.status.getValue() === ConnectionStatus.Online) {
        this.updateNetworkStatus(ConnectionStatus.Offline);
      }
    });

    this.network.onConnect().subscribe((state: any) => {
      if (this.status.getValue() === ConnectionStatus.Offline) {
        this.updateNetworkStatus(ConnectionStatus.Online);
      }
    });
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);

    if (status === ConnectionStatus.Offline) {
      if (environment.offlineEnabled !== true) {
        return this.toastService.error('Offline mode is disabled. Please connect to the internet.');
      }
      await this.toastService.warning('Device is offline reloading app please wait...').then((res) => {
        setTimeout(() => {
          location.reload();
        }, 2500);
      });
      return;
    }
    await this.toastService.success('Device is back online.');
  }
}
