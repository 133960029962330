import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalController} from '@ionic/angular';
import {BlockScreenPage} from '../global/block-screen/block-screen.page';

@Injectable({
  providedIn: 'root'
})
export class LandscapeService {
  public screenBlocked = new Subject();

  public screenUnBlocked = new Subject();

  constructor(private modalCtrl: ModalController) {
  }

  async blockScreen(message: string, color: string = 'warning') {
    this.unblockScreen();
    const modal = await this.modalCtrl.create({
      component: BlockScreenPage,
      componentProps: {message, color},
      backdropDismiss: false
    });

    return await modal.present();
  }

  unblockScreen() {
    this.modalCtrl.dismiss();
  }
}
