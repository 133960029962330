import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {delay, finalize, Observable} from 'rxjs';
import {OfflineSyncService} from '../services/offline/offline-sync.service';
import {ConnectionStatus, NetworkStatusService} from '../services/network-status.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogApiCallsService {

  constructor(private offlineService: OfflineSyncService, private network: NetworkStatusService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/login') || req.params.get('dboff')) {
      return next.handle(req);
    }
    if (req.method.toLocaleLowerCase() !== 'get' && (environment.forceOffline || (this.network.getCurrentNetworkStatus() === ConnectionStatus.Offline && environment.offlineEnabled))) {
      return next.handle(req).pipe(
        delay(1000),
        finalize(() => {
          this.offlineService.save(req.url, req.method, req.body);
        })
      );
    }

    return next.handle(req);
  }
}
