export const cachableEndPoint = [
  'activities',
  'areas',
  'employee',
  'leave-days',
  'employee-clocking',
  'employee-enrollment',
  'exam-check-list',
  'gang-supervisor',
  'gang-unit',
  'half-levels',
  'labour-availability',
  'labour-unavailables',
  'leave-roaster',
  'not-on-gang',
  'safe-declaration',
  'safe-declaration-gangs',
  'gang-transfer',
  'working-places',
  'employee-enrollment-validate'
];

export const endPointsWithDates = ['employee-clocking', 'leave-days'];

export const allowedFilters = {
  employee: ['is_enrolled', 'gang'],
};

export const filterSearchAdditionalFields = {
  '/labour-availability': {gang_unit_id: 'gangs'},
  '/activities': {gang_unit_id: 'gangs'},
  '/half-levels': {gang_unit_id: 'gangs'},
  '/working-places': {half_level_id: 'levels'},
  '/employee-availability': {gang_unit_id: 'gang_unit_id'}
};

export const additionalFields = [
  'first_name', 'last_name', 'industry_number', 'supervisor_id', 'is_enrolled'
];

export const allowedSearchParams = {
  employee: ['first_name', 'last_name', 'industry_number'],
  'employee-clocking': ['first_name', 'last_name', 'industry_number'],
  'gang-unit': ['name'],
  'employee-enrollment': ['first_name', 'last_name', 'industry_number', 'created_by'],
  'labour-availability': [],
  'employee-availability': ['first_name', 'last_name', 'industry_number', 'occupation_id', 'available_type'],
  'not-on-gang': ['first_name', 'last_name', 'industry_number', 'has_transferred'],
  'gang-transfer': ['first_name', 'last_name', 'industry_number'],
  'gang-supervisor': ['is_supervisor', 'name'],
  'leave-days': ['first_name', 'last_name', 'industry_number', 'job_name'],
  'labour-unavailables': ['first_name', 'last_name', 'industry_number', 'type'],
  'leave-roaster': ['first_name', 'last_name', 'industry_number'],
  'safe-declaration-gangs': ['supervisor_id'],
  'safe-declaration': ['miner_id'],
  activities: ['name'],
  'working-places': ['name'],
  areas: ['name'],
  'half-levels': ['name'],
  'exam-check-list': []
};
