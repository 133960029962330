import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {IonicSelectableModule} from 'ionic-selectable';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {IonicStorageModule} from '@ionic/storage-angular';
import {Network} from '@ionic-native/network/ngx';
import {interceptorProviders} from '../environments/interceptors';
import {BlockScreenPageModule} from './global/block-screen/block-screen.module';
import {Device} from '@ionic-native/device/ngx';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BlockScreenPageModule,
    IonicSelectableModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule
  ],
  providers: [
    Device,
    Network,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    interceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
