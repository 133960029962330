import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AdditionalRequestBodyService} from '../app/interceptors/additional-request-body.service';
import {LogApiCallsService} from '../app/interceptors/log-api-calls.service';
import {LogApiGetResponseService} from '../app/interceptors/log-api-get-response.service';
import {OfflineResponseService} from '../app/interceptors/offline-response.service';
import {LoadingBarService} from '../app/services/loading-bar.service';
import {ErrorHandler} from "@angular/core";
import {DttsExceptionsService} from "../app/services/dtts-exceptions.service";

export const interceptorProviders =
  [
    // {provide: HTTP_INTERCEPTORS, useClass: LoadingBarService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AdditionalRequestBodyService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LogApiGetResponseService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LogApiCallsService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: OfflineResponseService, multi: true},
    {provide: ErrorHandler, useClass: DttsExceptionsService}
  ];
