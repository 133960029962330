import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NotificationService} from './notification.service';
import {ConnectionStatus, NetworkStatusService} from './network-status.service';
import {environment} from '../../environments/environment';
import {Platform} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {LandscapeService} from './landscape.service';

const TOKEN_KEY = environment.tokenKey;

@Injectable({
  providedIn: 'root'
})
export class SyncToServerService {

  isLocked = false;

  readonly intervalDuration = 30000;

  readonly flag = environment.syncStateName;

  isRunning: BehaviorSubject<boolean> = new BehaviorSubject(false);
  releaseScreenLock: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private notification: NotificationService,
              private http: HttpClient,
              private screen: LandscapeService,
              private platform: Platform,
              private networkService: NetworkStatusService) {
  }

  isBackOnline() {
    this.platform.ready().then(() => {
      if (this.isMobilePlatform() && !this.hasDeviceUpdatedServer()) {
        this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
          if (status === ConnectionStatus.Online && !this.isLocked) {
            this.screen.blockScreen('Synchronization in progress').then(() => {
              this.isLocked = true;
              this.startCycle();
            });
          } else {
            localStorage.setItem(environment.syncStateName, '0');
          }
        });
      }
    });
  }

  startCycle() {
    this.releaseScreenLock.subscribe((res: boolean) => {
      if (res && this.isLocked) {
        this.screen.unblockScreen();
        this.isLocked = false;
      }
    });

    const runner = setInterval(() => {
      if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline || environment.offlineEnabled !== true) {
        return false;
      }
    });
  }

  private getParams(urlParams) {
    const savedUser: any = localStorage.getItem('user-info');
    if (savedUser && savedUser.uuid) {
      const user = JSON.parse(savedUser);
      urlParams.user_type = user.type;
    }
    urlParams.access_token = localStorage.getItem(TOKEN_KEY);
    urlParams.device_id = this.getDeviceId();
    urlParams.time = new Date().getTime();
    return urlParams;
  }

  private isMobilePlatform(): boolean {
    return (this.platform.is('android') && this.platform.is('cordova'));
  }

  private hasDeviceUpdatedServer(): boolean {
    return +localStorage.getItem(this.flag) > 0;
  }

  private getDeviceId(): string {
    return '5adt8g0spvneluyw2bih';
  }
}

