import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ToastController} from '@ionic/angular';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {Device} from '@ionic-native/device/ngx';

const API_URL: string = environment.apiUrl;
const TOKEN_KEY = environment.tokenKey;

export enum ConnectionStatus {
  Online = 1,
  Offline = 0
}

@Injectable({
  providedIn: 'root'
})
export abstract class ApiService {

  public created = new Subject();

  public updated = new Subject();

  public networkState = new BehaviorSubject(ConnectionStatus.Offline);

  protected service;

  protected constructor(
    protected http: HttpClient,
    protected toast: ToastController,
    protected router?: Router,
    protected device?: Device
  ) {
  }

  public setServiceName(service) {
    const cleanServiceName = service.replace('/', '');
    this.service = cleanServiceName;
    return this;
  }

  public async showError(message, position: any = 'top', duration = 2500) {
    this.service = null;
    const toast = await this.toast.create({
      message,
      duration,
      position,
      translucent: true,
      color: 'danger',
    });
    await toast.present();
  }

  public async showDebug(message, position: any = 'top', duration = 5000) {
    this.service = null;
    const toast = await this.toast.create({
      message,
      duration,
      position,
      translucent: true,
      color: 'warning',
    });
    await toast.present();
  }

  public async showInfo(message, position: any = 'top', duration = 2500, color = 'primary') {
    this.service = null;

    const toast = await this.toast.create({
      message,
      duration,
      position,
      translucent: true,
      color,
    });
    await toast.present();
  }

  public async showWarning(message, position: any = 'top', duration = 2500) {
    this.service = null;

    const toast = await this.toast.create({
      message,
      duration,
      position,
      translucent: true,
      color: 'warning',
    });
    await toast.present();
  }

  public async showSuccess(text, position: any = 'top', duration = 2500) {
    const toast = await this.toast.create({
      message: text,
      duration,
      position,
      translucent: true,
      color: 'success',
    });
    await toast.present();
  }

  public create(params, successCallBack?, urlParams: any = {}, forceMsg?) {
    return this.http.post(this.getBaseUrl() + '/' + this.getServiceUrl(), params, {params: this.getParams(urlParams)})
      .subscribe((result: any) => {
        this.service = null;
        this.created.next(result);
        if (typeof successCallBack === 'function') {
          successCallBack(result);
        } else {
          this.showSuccess(result);
        }
        if (forceMsg) {
          this.showSuccess('Operation completed.');
        }
      }, (error) => {
        this.logoutAndRedirect(error);
        this.showError(error.error);
      });
  }

  public update(id, packet, successCallBack, forceMsg?) {
    const urlParams: any = {};
    return this.http.patch(this.getBaseUrl() + '/' + this.getServiceUrl() + '/' + id, packet, {params: this.getParams(urlParams)})
      .subscribe((result: any) => {
        this.service = null;
        if (typeof successCallBack === 'function') {
          successCallBack(result);
        } else {
          this.showSuccess(result);
        }
        if (forceMsg) {
          this.showSuccess(result);
        }
      }, (error) => {
        this.logoutAndRedirect(error);
        this.showError(error.error);
      });
  }

  public get(successCallBack, urlParams: any = {}) {
    return this.http.get(this.getBaseUrl() + '/' + this.getServiceUrl(), {params: this.getParams(urlParams)})
      .subscribe((result: any) => {
        this.service = null;
        successCallBack(result);
      }, (error) => {
        this.logoutAndRedirect(error);
        this.showError(error.error);
      });
  }

  public getSingle(id, successCallBack, urlParams: any = {}) {
    return this.http.get(this.getBaseUrl() + '/' + this.getServiceUrl() + '/' + id, {params: this.getParams(urlParams)})
      .subscribe((result: any) => {
        this.service = null;
        successCallBack(result);
      }, (error) => {
        this.logoutAndRedirect(error);
        this.showError(error.error);
      });
  }

  public delete(id, successCallBack?, urlParams: any = {}, forceMsg?) {
    return this.http.delete(this.getBaseUrl() + '/' + this.getServiceUrl() + '/' + id, {params: this.getParams(urlParams)})
      .subscribe((result: any) => {
        this.service = null;
        if (typeof successCallBack === 'function') {
          successCallBack(result);
        } else {
          this.showSuccess(result);
        }
        if (forceMsg) {
          this.showSuccess(result);
        }
      }, (error) => {
        this.logoutAndRedirect(error);
        this.showError(error.error);
      });
  }

  public findByValue(objects, attribute, value) {
    return objects.find(obj => obj[attribute] === value);
  }

  public serviceName() {
    return '/' + this.getServiceName();
  }

  protected getBaseUrl() {
    return API_URL;
  }

  protected getDeviceUuid(): any {
    return this.device.uuid;
  }

  protected getParams(urlParams) {
    const savedUser: any = localStorage.getItem('user-info');
    const savedEmployee: any = localStorage.getItem('employee-info');

    if (savedUser) {
      const user = JSON.parse(savedUser);
      if (user.hasOwnProperty('user_type') && user.user_type) {
        urlParams.user_type = user.type;
      }
    }
    if (savedEmployee) {
      const employee = JSON.parse(savedEmployee);
      urlParams.miner = employee.industry_number;
    }
    // urlParams.device_id = this.getDeviceUuid();
    urlParams.access_token = localStorage.getItem(TOKEN_KEY);
    urlParams.time = new Date().getTime();
    return urlParams;
  }

  private getServiceUrl() {
    if (this.service) {
      return this.service;
    }

    return this.getServiceName();
  }

  private logoutAndRedirect(error) {
    if (error.status === 401) {
      localStorage.clear();
      this.showInfo('For your own security. Please login to continue.');
      this.router.navigate(['login']);
    }
  }

  protected abstract getServiceName(): string;
}
