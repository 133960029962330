import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmployeeFilterService {

  public process(service, filters, params, filteredData, savedData) {
    if (service === 'employee' && filters.length > 0) {
      if (params.hasOwnProperty('is_enrolled') && params.hasOwnProperty('gang_unit_id')) {
        savedData.forEach((item, index) => {
          item.current_index = index;
          item.service_track = service;
          if (item.is_enrolled === params.is_enrolled && item.gang_unit.uuid === params.gang_unit_id) {
            filteredData.push(item);
          }
        });
      } else if (params.hasOwnProperty('shift_direction') && params.hasOwnProperty('gang_unit_id')) {
        savedData.forEach((item, index) => {
          item.current_index = index;
          item.service_track = service;
          if (params.shift_direction === 'in') {
            if (!item.attendance.underground_in_time && item.gang_unit.uuid === params.gang_unit_id) {
              filteredData.push(item);
            }
          }

          if (params.shift_direction === 'out') {
            if (
              !item.attendance.underground_out_time
              && item.attendance.underground_in_time
              && item.gang_unit.uuid === params.gang_unit_id) {
              filteredData.push(item);
            }
          }
        });
      } else if (params.hasOwnProperty('occupation_id') && params.hasOwnProperty('available_type')) {
        savedData.forEach((item, index) => {
          item.current_index = index;
          item.service_track = service;
          if (params.available_type === 'no_bio') {
            if (!item.attendance.underground_in_time && item.occupation.uuid === params.occupation_id) {
              filteredData.push(item);
            }
          }
          if (params.available_type === 'bio') {
            if (item.attendance.underground_in_time && item.occupation.uuid === params.occupation_id) {
              filteredData.push(item);
            }
          }
          if (params.available_type === 'clocked') {
            if (item.attendance.surface_in_time && item.occupation.uuid === params.occupation_id) {
              filteredData.push(item);
            }
          }
        });
      } else if (params.hasOwnProperty('gang_unit_id')) {
        savedData.forEach((item, index) => {
          item.current_index = index;
          item.service_track = service;
          if (item.gang_unit.uuid === params.gang_unit_id) {
            filteredData.push(item);
          }
        });
      }
    }
  }
}
