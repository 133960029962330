import {Injectable} from '@angular/core';
import {interval, mergeMap, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClockService {

  getClock(): Observable<Date> {
    return interval(1000).pipe(
      mergeMap(() => of(new Date()))
    );
  }

  getCurrentDate(currentDate: Date): string {
    const cDay        = currentDate.getDate();
    const cMonth      = currentDate.getMonth() + 1;
    const cYear       = currentDate.getFullYear();

    return `${cYear}-${cMonth}-${cDay}`;
  }

  getStartDate(currentDate: Date): string {
    const date = new Date().setDate(currentDate.getDate() - 30);

    return this.getCurrentDate(new Date(date));
  }


}
