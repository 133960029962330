import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toast: ToastController) {
  }

  async success(text, duration = 3000) {
    const toast = await this.toast.create({
      message: text,
      duration,
      position: 'middle',
      color: 'success',
    });
    await toast.present();
  }

  async error(text) {
    const toast = await this.toast.create({
      message: text,
      duration: 2500,
      position: 'middle',
      color: 'danger',
    });
    await toast.present();
  }

  async info(text, duration = 3000) {
    const toast = await this.toast.create({
      message: text,
      duration,
      position: 'middle',
      color: 'primary',
    });
    await toast.present();
  }

  async warning(text, duration = 3000) {
    const toast = await this.toast.create({
      message: text,
      duration,
      position: 'middle',
      color: 'warning',
    });
    await toast.present();
  }
}
