import {Injectable} from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  time24Hour() {
    return moment().format('HH:mm');
  }

  dateString() {
    return moment().format('YYYY-MM-DD');
  }

  dateXDaysAgo(days = 30) {
    return moment().subtract(days, 'days').format('YYYY-MM-DD');
  }
}
