import {Injectable} from '@angular/core';
import {v4 as uuidv4} from 'uuid';
import {Platform} from '@ionic/angular';
import {allowedSearchParams} from '../../../environments/cachable-end-point';
import {NotificationService} from '../notification.service';
import {Subject} from 'rxjs';
import {ConnectionStatus, NetworkStatusService} from '../network-status.service';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from '../local-storage-service';
import {EmployeeFilterService} from './employee-filter.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineSyncService {

  public itemChanged = new Subject();

  constructor(
    private platform: Platform,
    private notification: NotificationService,
    private network: NetworkStatusService,
    private localStorage: LocalStorageService,
    private employeeFilter: EmployeeFilterService,
  ) {
    this.itemChanged.subscribe((item) => {
      this.syncUpdated(item);
    });

  }

  cache(url, data: any) {
  }

  public getFromCache(service, params): any {
    const response: any = {};
    const page = params.page;
    const offset = (page - 1) * 15;
    const cleanServiceName = service.replace('/', '');

    if (cleanServiceName === 'employee-enrollment-validate') {
      const prints = localStorage.getItem(cleanServiceName);
      if (!prints) {
        response.items = [];
        this.notification.error('The biometric database is empty.');
      } else {
        response.items = prints.split(',');
      }
      response.employees = JSON.parse(localStorage.getItem('bio_people'));
      response.total = response.items.length;
      response.next_page = (response.total / 15) > page ? (page + 1) : 0;

      return response;
    }

    let savedData: any = localStorage.getItem(cleanServiceName);
    if (savedData) {
      savedData = JSON.parse(savedData);

      savedData = this.getFiltered(savedData, cleanServiceName, params);

      response.total = savedData.length;
      response.next_page = (response.total / 15) > page ? (page + 1) : 0;
      savedData = savedData.slice(offset, offset + 14);
    } else {
      savedData = [];
    }
    response.items = savedData;

    return response;
  }

  public syncUpdated(item: any) {
    if (
      environment.forceOffline === false
      && (this.network.getCurrentNetworkStatus() !== ConnectionStatus.Offline || environment.offlineEnabled !== true)
    ) {
      return;
    }
    if (item.service_track && item.hasOwnProperty('current_index')) {
      let savedData: any = localStorage.getItem(item.service_track);
      if (savedData) {
        savedData = JSON.parse(savedData);
        savedData[item.current_index] = item;
        localStorage.setItem(item.service_track, JSON.stringify(savedData));
      }
    }
  }

  public save(url, method: string, data) {
    const packet: any = {};
    packet.uuid = data.uuid ? data.uuid : uuidv4();
    packet.payload = JSON.stringify(data);
    packet.method_type = method.toLowerCase();
    packet.service_name = this.getServiceName(url);
    packet.uri = url;

    if (data.name) {
      packet.name = data.name;
    }
    this.localStorage.savePendingSync(packet);
  }

  public getServiceName(url: string) {
    return new URL(url).pathname.replace('/api', '');
  }

  public addToAvailability(employee: any) {
    if (environment.forceOffline !== true &&
      (this.network.getCurrentNetworkStatus() !== ConnectionStatus.Offline || environment.offlineEnabled !== true)) {
      return;
    }
    const availability = JSON.parse(localStorage.getItem('labour-availability'));

    const savedData = [];
    availability.forEach((item) => {
      if (employee.occupation && item.uuid === employee.occupation.uuid) {
        if (employee.attendance.surface_in_time) {
          item.clocked++;
        }
        if (employee.attendance.underground_in_time) {
          item.bio++;
          item.no_bio--;
        }
      }

      savedData.push(item);
    });
    localStorage.setItem('labour-availability', JSON.stringify(savedData));
  }

  offlineDisabledNotify() {
    return this.notification.error('Offline disabled. Please activate internet connection.');
  }

  private getFiltered(savedData, service, params): any {
    delete params.miner;
    let filteredData = [];
    const filters = Object.keys(params);
    const disAllowed = ['q', 'page'];
    let disIndex;
    for (const disAll of disAllowed) {
      disIndex = filters.indexOf(disAll);
      if (disIndex >= 0) {
        filters.splice(disIndex, 1);
      }
    }

    this.employeeFilter.process(service, filters, params, filteredData, savedData);

    if (filteredData.length === 0 && filters.length === 0) {
      filteredData = savedData;
    }
    let searchedData = [];

    if (allowedSearchParams.hasOwnProperty(service) && params.hasOwnProperty('q') && params.q.length >= 3) {
      filteredData.forEach((item, index) => {
        if (!item.hasOwnProperty('current_index')) {
          item.current_index = index;
        }
        if (!item.hasOwnProperty('service_track')) {
          item.service_track = service;
        }
        allowedSearchParams[service].forEach((field) => {
          if (item.hasOwnProperty(field) && item[field].toLowerCase().includes(params.q.toLowerCase())) {
            searchedData.push(item);
          }
        });
      });
    } else {
      searchedData = filteredData;
    }

    return searchedData;
  }
}
