import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {OfflineSyncService} from '../services/offline/offline-sync.service';
import {ConnectionStatus, NetworkStatusService} from '../services/network-status.service';

@Injectable({
  providedIn: 'root'
})
export class LogApiGetResponseService {

  constructor(private offlineService: OfflineSyncService, private network: NetworkStatusService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/login') || req.params.get('dboff')) {
      return next.handle(req);
    }

    if (this.network.getCurrentNetworkStatus() !== ConnectionStatus.Offline) {
      return next.handle(req).pipe(map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.offlineService.cache(event.url, event.body);
        }
        return event;
      }));
    }
    return next.handle(req);
  }
}
