export const environment = {
  production: true,
  apiUrl: 'https://api2.edtt.co.za/api',
  tokenKey: '',
  clientSecret: '',
  sqliteDBName: 'mss-dtts.db',
  syncStateName: 'has_synced',
  pendingSyncName: 'pending_sync',
  offlineToken: '181f985b53ef4586ba442f8db0fd5cb9',
  offlineEnabled: true,
  forceOffline:false
};
