import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-block-screen',
  templateUrl: './block-screen.page.html',
  styleUrls: ['./block-screen.page.scss'],
})
export class BlockScreenPage implements OnInit {

  message;

  color = 'warning';

  constructor() {
  }

  ngOnInit() {
  }

}
