import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ToastController} from '@ionic/angular';
import {BehaviorSubject} from 'rxjs';
import {ApiService} from './api.service';
import {ConnectionStatus, NetworkStatusService} from './network-status.service';

const API_URL: string = environment.apiUrl;
const TOKEN_KEY = environment.tokenKey;
const USER_INFO = 'user-info';
const EMPLOYEE_INFO = 'employee-info';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends ApiService {

  public authenticationState = new BehaviorSubject(false);

  public userPermissions: any = new BehaviorSubject({});

  constructor(
    protected http: HttpClient,
    private toastController: ToastController,
    private networkService: NetworkStatusService
  ) {
    super(http, toastController);
    this.checkToken();
  }

  public isAuthenticated() {
    return this.authenticationState.value;
  }

  public checkToken() {
    const res = localStorage.getItem(TOKEN_KEY);
    if (res) {
      this.authenticationState.next(true);
      return true;
    }
    return false;
  }

  public signUp(params, successCallBack) {
    return this.http.post(API_URL + '/user', params)
      .subscribe((result: any) => {
        localStorage.setItem(TOKEN_KEY, result.access_token);
        localStorage.setItem(USER_INFO, JSON.stringify(result.user_details));
        this.authenticationState.next(true);
        successCallBack(result);
      }, (error) => {
        this.showError(error.error);
      });
  }

  passwordReset(params, successCallBack) {
    return this.http.post(API_URL + '/user/reset-link', params)
      .subscribe((result: any) => {
        successCallBack(result);
      }, (error) => {
        this.showError(error.error);
      });
  }

  public login(options: any, successCallBack) {
    if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      return this.showError('You must be connected to the internet to login.');
    }
    options.grant_type = 'client_credentials';
    const params = new HttpParams({
      fromObject: options
    });
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    this.http.post(API_URL + '/login', params, httpOptions)
      .subscribe((result: any) => {
        localStorage.setItem(TOKEN_KEY, result.access_token);
        localStorage.setItem(USER_INFO, JSON.stringify(result.user_details));
        localStorage.setItem(EMPLOYEE_INFO, JSON.stringify(result.employee_details));

        if (!result.employee_details) {
          this.showError('Employee information not found on user.');
          setTimeout(() => {
            this.showError('Some functionality may not work as expected.');
          }, 3000);

          setTimeout(() => {
            this.showError('Are you an employee?.');
          }, 6000);
        }
        this.userPermissions.next({
          enroll_users_c: result.user_details.enroll_users_c,
          enroll_users_r: result.user_details.enroll_users_r,
          enroll_users_u: result.user_details.enroll_users_u,
          enroll_users_d: result.user_details.enroll_users_d
        });
        this.authenticationState.next(true);
        successCallBack(result);
      }, (error) => {
        console.log(error);
        this.showError(error.error);
      });
  }

  public logout() {
    localStorage.clear();
    this.authenticationState.next(false);
  }

  public getUserId() {
    const user: any = this.getUser();
    if (user) {
      return user.uuid;
    }
  }

  public hasPermission(permission: string) {
    const isSuperAdmin = JSON.parse(localStorage.getItem(USER_INFO)).is_super_admin;
    if (isSuperAdmin) {
      return isSuperAdmin;
    }
    return this.getUser()[permission];
  }

  public getUser() {
    const user: any = localStorage.getItem(USER_INFO);
    if (user) {
      return JSON.parse(user);
    }
  }

  public getEmployee() {
    const employee: any = localStorage.getItem(EMPLOYEE_INFO);
    if (employee) {
      return JSON.parse(employee);
    }
  }

  public isSuperAdmin(): boolean {
    return JSON.parse(localStorage.getItem(USER_INFO)).is_super_admin;
  }

  protected getServiceName(): string {
    return '';
  }
}
