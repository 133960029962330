import {Injectable} from '@angular/core';
import {ConnectionStatus, NetworkStatusService} from '../services/network-status.service';
import {HttpEvent, HttpHandler, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {v4 as uuidv4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AdditionalRequestBodyService {

  constructor(private network: NetworkStatusService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/login')) {
      return next.handle(req);
    }

    if (req.method !== 'GET') {

      const uuid = uuidv4();

      let params = req.params;

      if (this.network.getCurrentNetworkStatus() !== ConnectionStatus.Online) {
        params = (req.params ? req.params : new HttpParams()).set('offline_token', environment.offlineToken);
      }
      const action_time = Math.floor(Date.now() / 1000);
      const body = {...req.body, uuid, action_time};
      req = req.clone({body, params});
    }

    return next.handle(req);
  }
}
