import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() {
  }

  public setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  public getItem(key: string) {
    return localStorage.getItem(key);
  }

  public removeItem(key: string) {
    localStorage.removeItem(key);
  }

  public clear() {
    localStorage.clear();
  }

  public saveCache(service, items) {
    if (service === 'employee-enrollment-validate') {
      return localStorage.setItem(service, items.toString());
    }
    const savedData = localStorage.getItem(service);
    let dataToSave = items;
    if (savedData) {
      dataToSave = JSON.parse(savedData);
      dataToSave = dataToSave.concat(items);
    }

    localStorage.setItem(service, JSON.stringify(dataToSave));
  }

  public savePendingSync(packet) {
    const savedData = localStorage.getItem(environment.pendingSyncName);
    let dataToSave = [packet];
    if (savedData) {
      dataToSave = JSON.parse(savedData);
      dataToSave.push(packet);
    }

    localStorage.setItem(environment.pendingSyncName, JSON.stringify(dataToSave));
  }

  public updateAllPendingSync(packets) {
    localStorage.setItem(environment.pendingSyncName, JSON.stringify(packets));
  }

  public getPendingSync() {
    const savedData = localStorage.getItem(environment.pendingSyncName);
    if (savedData) {
      return JSON.parse(savedData);
    }

    return [];
  }
}
