import {Injectable} from '@angular/core';

const USER_INFO = 'user-info';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: any;

  constructor() {
    this.user = this.getUser();
  }

  public getUser() {
    const user: any = localStorage.getItem(USER_INFO);
    if (user) {
      return JSON.parse(user);
    }
  }

  public getUserId() {
    return this.getUser().uuid;
  }

  public getClientId() {
    return this.getUser().client.uuid;
  }

  public isSuperAdmin() {
    if (!this.user) {
      return false;
    }
    return this.user.type === 1;
  }

  public canEnrolUsers() {
    if (!this.user) {
      return false;
    }
    return this.user.enroll_users_c || this.user.enroll_users_d || this.user.enroll_users_r || this.user.enroll_users_u;
  }
}
